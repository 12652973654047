import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const Contacts = () => {
    const { t } = useTranslation()
    const contacts = [
        {
            heading: t('Pořadatel'),
            lines: ['AK Rakovník', 'www.lkrk.cz', 'E-mail: info@lkrk.cz', 'Tel.: +420 313 512 277'],
        },
        {
            heading: t('Ředitel soutěže'),
            lines: ['Jan Hlaváček', 'Tel.: +420 727 903 287', 'E-mail: jan.gorgoj@seznam.cz'],
        },
        {
            heading: t('Provozní a technické dotazy'),
            lines: ['Jan Konečný', 'Tel.: +420 777 193 007', 'E-mail: 1stCLJohann@seznam.cz'],
        },
        {
            heading: t('Přihlášky'),
            lines: ['Tel.: +420 777 193 007', 'E-mail: rd2022@seznam.cz'],
        },
        {
            heading: t('Web a vyhodnocování'),
            lines: ['Jan Vožeh', 'Tel.: +420 776 006 638', 'E-mail: vozeh.jan@hotmail.com'],
        },
    ]

    const formatLine = (line) => {
        return <p key={line}>{line}</p>
    }

    return (
        <div>
            <h1>{t('Kontakty')}</h1>
            {contacts.map((contact) => {
                return (
                    <Fragment key={contact.heading}>
                        <h2>{contact.heading}</h2>
                        {contact.lines.map((line) => formatLine(line))}
                    </Fragment>
                )
            })}
        </div>
    )
}

export default Contacts
