import React from 'react'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { version } from '../../package.json'

const Footer = () => {
    const { t } = useTranslation()
    return (
        <Container className="foot-container">
            <footer>
                <span className="text"><b>
                        © {process.env.REACT_APP_TITLE}{' '}
                        </b>
                </span>
                <span className="foot-credits">
                    runs on Tcup engine
                    by{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/harastaivan">
                        @harastaivan
                    </a>
                </span>
            </footer>
        </Container>
    )
}

export default Footer
