export type Sponsor = {
    name: string
    image: string
    url?: string
}

const getSponsors = (): Sponsor[] => {
    return [
        {
            name: 'Pivovar Bakalář',
            image: 'assets/img/sponsors/bakalar.jpg',
            url: 'https://www.pivobakalar.cz/',
        },
        {
            name: 'Kamenictví HAVLÍČEK',
            image: 'assets/img/sponsors/konecny.png',
            url: 'https://kamenosocharstvi-havlicek.webnode.cz/',
        },
        {
            name: 'Skysight.io',
            image: 'assets/img/sponsors/skysight.png',
            url: 'https://www.skysight.io/',
        }
    ]
}

const useSponsors = (): Sponsor[] => {
    const sponsors = getSponsors()

    return sponsors
}

export default useSponsors
