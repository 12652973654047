import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export default function Archive() {
    const { t } = useTranslation()

    return (
        <div>
            <h1>{t('Archiv předchozích ročníků')}</h1>
            <ListGroup>
                <ListGroupItem tag="a" href="http://rd2022.aeroklub-rakovnik.cz" target="_blank">
                    Rakovnická dlaždice 2022
                </ListGroupItem>
                <ListGroupItem tag="a" href="http://www.gliding.cz/souteze/2021/rd/" target="_blank">
                    Rakovnická dlaždice 2021
                </ListGroupItem>
            </ListGroup>
        </div>
    )
}
